import React, { useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Image, Button, Form, Spinner } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import background from '../assets/img/Hintergrund8_16_9.png';
import minbackground from '../assets/img/minimized/Hintergrund8_16_9.png';
import res_bg from '../assets/img/responsive_backgrounds/Background8_Responsive.png';
import min_res_bg from '../assets/img/responsive_backgrounds/minimized/Background8_Responsive.png';
import Headline from './subcomponent/Headline';
import { useDispatch, useSelector } from 'react-redux';
import { setFirstName, setLastName, setEmail, setPage, setLikeIndex, setConsider } from '../redux/slices/ContactSlice';
import Section from './Section';
import { isMobile, responsive } from '../App';
import checkmark from '../assets/img/icon/Bestätigung.svg';

function ContactSection() {


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const apiUrl = window.env.REACT_APP_API_URL;
    const sitekey = window.env.REACT_APP_GOOGLE_CAPTCHA_API_KEY;

    const { t } = useTranslation(['contact']);
    const dispatch = useDispatch();

    const acceptedCookieCategories = useSelector((state) => state.cookieSlice.acceptedCookieCategories);
    const necessaryCookiesAccepted = acceptedCookieCategories.includes("necessary");

    const likes = t('project_genesis.likes_options').split("///");
    const likes_de = t('project_genesis.likes_options', {lng:"de"}).split("///");

    const state = useSelector(state => state.contact);

    const isEmailValid = emailRegex.test(state.email);

    const checkInformation = () => {
        return state.first_name.length > 0 && state.last_name.length > 0 && isEmailValid;
    };
    const checkLogin = () => {
        return state.likesIndex.id !== -1 && state.consider.length > 0;
    }

    const captchaRef = React.createRef();

    const onSubmit = () => {
        captchaRef.current.execute();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'signedUpForNewsletter',
        });
    }

    const onChange = (value) => {
        if (value !== null) {
            dispatch(setPage(2));
            fetch(`${apiUrl}/api/newsletter`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                cache: "no-cache",
                body: JSON.stringify({
                    captcha_key: value,
                    firstname: state.first_name,
                    lastname: state.last_name,
                    email: state.email,
                    consider: state.consider,
                    likes: likes_de[state.likesIndex.id]
                })
            }).then(res => {
                if (res.ok) {
                    dispatch(setPage(3));
                } else {
                    dispatch(setPage(1));
                    window.grecaptcha.reset();
                    if (res.status == 500) {
                        alert("This E-Mail is already registered!");
                    } else {
                        alert("Error! Try again.");
                    }
                }
            }).catch(e => {
                dispatch(setPage(1));
                window.grecaptcha.reset();
                alert("Unexpected Error!");
            });
        }
    };

    const contFunc = (state.page == 1) ? () => {
        onSubmit();
    } : () => {
        dispatch(setPage(1));
    };

    var isLikesHovering = false;
    const likesDropdown = "#a-contact > div > div.contact-box > div > div";
    const toggleLikesDropdown = () => {
        var e = document.body.querySelector(likesDropdown);
        e.classList.toggle("hover");
        //unhoverConsiderDropdown(true);
    };
    const unhoverLikesDropdown = (force) => {
        if (force || !isLikesHovering) {
            var e = document.body.querySelector(likesDropdown);
            e.classList.remove("hover");
            isLikesHovering = false;
        }
    }
    const dropdownLikesOptionClick = (v, i) => {
        dispatch(setLikeIndex({id: i, text: v}));
        unhoverLikesDropdown(true);
    };

    /*var isConsiderHovering = false;
    const considerDropdown = "#a-contact > div > div.contact-box > div:nth-child(6) > div";
    const toggleConsiderDropdown = () => {
        var e = document.body.querySelector(considerDropdown);
        e.classList.toggle("hover");
        unhoverLikesDropdown(true);
    };
    const unhoverConsiderDropdown = (force) => {
        if (force || !isConsiderHovering) {
            var e = document.body.querySelector(considerDropdown);
            e.classList.remove("hover");
            isConsiderHovering = false;
        }
    }
    const dropdownConsiderOptionClick = (v, i) => {
        dispatch(setConsiderIndex({id: i, text: v}));
        unhoverConsiderDropdown(true);
    };*/

    var content;
    switch (state.page) {
        case 0: 
            content = (
                <div className='contact-box'>
                    <p>{t("project_genesis.first_name_hint")}</p>
                    <input className="inputSize" type="text" value={state.first_name} onChange={ev => dispatch(setFirstName(ev.target.value.substring(0, 50)))} />
                    <br />
                    <p>{t("project_genesis.last_name_hint")}</p>
                    <input className="inputSize" type="text" value={state.last_name} onChange={ev => dispatch(setLastName(ev.target.value.substring(0, 50)))} />
                    <br />
                    <p>{t("project_genesis.email_hint")}</p>
                    <input className="inputSize" type="text" value={state.email} onChange={ev => dispatch(setEmail(ev.target.value.substring(0, 50)))} />
                </div>
            );
            break;
        case 1:
            content = (
                <div className='contact-box'>
                    <p>{t("project_genesis.likes")}</p>
                    <div>
                        <p className="contact-dropdown-box inputSize" onClick={toggleLikesDropdown}>{state.likesIndex.text}</p>
                        <div className='contact-dropdown override_to_normal_scrollbar' onMouseOver={() => {isLikesHovering = true;}} onMouseOut={() => {isLikesHovering = false;}}>
                            {
                                Array.from(likes, (el, i) => {
                                    return (<p key={el} onClickCapture={() => dropdownLikesOptionClick(el, i)} >{el}</p>);
                                })
                            }
                        </div>
                    </div>
                    <p>{t("project_genesis.likes_question")}</p>
                    <br />
                    <p>{t("project_genesis.consider")}</p>
                    <input className="inputSize" type="text" value={state.consider} onChange={ev => dispatch(setConsider(ev.target.value.substring(0, 250)))} />
                    <p>{t("project_genesis.consider_question")}</p>
                </div>
            );
            break;
        case 2:
            content = (
                <div className='contact-box'>
                    <Spinner animation="border" variant="light"/>
                </div>
            );
            break;
        case 3:
            content = (
                <div className='contact-box success'>
                    <img src={checkmark}></img>
                    <p>{t('project_genesis.registered')}</p>
                </div>
            );
            break;
    }

    /*<div>
        <p className="contact-dropdown-box" onClick={toggleConsiderDropdown}>{state.considerIndex.text}</p>
        <div className='contact-dropdown override_to_normal_scrollbar' onMouseOver={() => {isConsiderHovering = true;}} onMouseOut={() => {isConsiderHovering = false;}}>
            {
                Array.from(considerations, (el, i) => {
                    return (<p key={el} onClickCapture={() => dropdownConsiderOptionClick(el, i)} >{el}</p>);
                })
            }
        </div>
    </div>*/

    return(
        <Section id="a-contact" bg={(responsive) ? res_bg : background} minbg={(responsive) ? min_res_bg : minbackground}>
            <Headline classList="headline-text-shadow" text={t('project_genesis.headline')} />
            <h2 className='contact-top-text' style={{textShadow: "2px 2px 4px black"}}>{t('project_genesis.top_text')}</h2>
            {content}

            {sitekey && necessaryCookiesAccepted ? (
            <ReCAPTCHA
                theme="dark"
                size="invisible"
                sitekey={sitekey}
                ref={captchaRef}
                onChange={onChange}
                asyncScriptOnLoad={null}
            />
            ) : (
                <h4 className='captchaNotice'>Loading ReCAPTCHA...</h4>
            )}
            <h4 className='captchaNotice'>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</h4>
            <div className='plan_nav_button_div contact-nav-buttons'>
                { (state.page > 0 && state.page < 2) && <Button onClick={() => dispatch(setPage(0))} disabled={state.page < 1} className='plan_back_button'>{t('project_genesis.nav.back')}</Button> }
                { (state.page < 2) && <Button onClick={contFunc} disabled={(state.page < 1) ? !checkInformation() : !checkLogin()} className='plan_cont_button'>{(state.page < 1) ? t('project_genesis.nav.continue') : t('project_genesis.nav.login')}</Button> }
            </div>
        </Section>
    );
}

export default ContactSection;