import React, { useRef } from 'react';
import { Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import background from '../assets/img/Hintergrund3_16_9.png';
import minbackground from '../assets/img/minimized/Hintergrund3_16_9.png';
import res_bg from '../assets/img/responsive_backgrounds/Background3_Responsive.png';
import min_res_bg from '../assets/img/responsive_backgrounds/minimized/Background3_Responsive.png';
import HowToShort from './subcomponent/HowToShort';
import Headline from './subcomponent/Headline';
import BulletTip from './subcomponent/BulletTip';
import SideScroller from './subcomponent/SideScroller';

/* gifs */
import g_ah from '../assets/gifs/App herunterladen.gif'
import g_b from '../assets/gifs/Buchen.gif'
import g_Mv from '../assets/gifs/Material vorbereiten.gif'
import g_tp from '../assets/gifs/Tour planen.gif'
import g_tr from '../assets/gifs/Tour reservieren.gif'
import Section from './Section';
import HowToMobileItem from './subcomponent/HowToMobileItem';

import { isMobile, responsive } from '../App';

function HowTo({ instant }) {

    const { t } = useTranslation(['howto']);

    window.addEventListener("scroll", ev => {
        for (let i = 1; i <= 5; ++i) {
            let b = document.getElementById(`b-${i}`);
            if (b !== null && b.classList.contains("tmpShow")) {
                b.classList.remove("tmpShow");
            }
        }
        var animDiv = document.getElementById("howto-animDiv");
        var HowToSection = document.querySelector("#a-howto > img");
        if (HowToSection !== null && animDiv !== null && (HowToSection.getBoundingClientRect().bottom * 1.4 - window.scrollY) < 1) { // Make subjects appear when scrolling down
            if (!animDiv.classList.contains("howto-fade-in")) {
                animDiv.classList.add("howto-fade-in");
                instant.current = true;
            }
        }
    });

    return(
        <Section id="a-howto" bg={(responsive) ? res_bg : background} minbg={(responsive) ? min_res_bg : minbackground}>
            <Headline cssClass="headline-howto" text={t('project_genesis.howto.headline')} classList="lower"/>
            {
                (isMobile) ? (
                    <SideScroller classList="no-top-pad no-bottom-pad">
                        <HowToMobileItem number={1} title={t('project_genesis.howto.shorts.plan.title')} description={t('project_genesis.howto.shorts.plan.description')} gif={g_tp}></HowToMobileItem>
                        <HowToMobileItem number={2} title={t('project_genesis.howto.shorts.preserve.title')} description={t('project_genesis.howto.shorts.preserve.description')} gif={g_Mv}></HowToMobileItem>
                        <HowToMobileItem number={3} title={t('project_genesis.howto.shorts.reserve.title')} description={t('project_genesis.howto.shorts.reserve.description')} gif={g_tr}></HowToMobileItem>
                        <HowToMobileItem number={4} title={t('project_genesis.howto.shorts.book.title')} description={t('project_genesis.howto.shorts.book.description')} gif={g_b}></HowToMobileItem>
                        <HowToMobileItem number={5} title={t('project_genesis.howto.shorts.download.title')} description={t('project_genesis.howto.shorts.download.description')} gif={g_ah}></HowToMobileItem>
                    </SideScroller>
                ) : (
                    <div>
                        <div id="howto-animDiv" className={((instant.current) ? "howto-instant" : "")}>
                            <HowToShort number={1} title={t('project_genesis.howto.shorts.plan.title')} description={t('project_genesis.howto.shorts.plan.description')}></HowToShort>
                            <HowToShort number={2} title={t('project_genesis.howto.shorts.preserve.title')} description={t('project_genesis.howto.shorts.preserve.description')}></HowToShort>
                            <HowToShort number={3} title={t('project_genesis.howto.shorts.reserve.title')} description={t('project_genesis.howto.shorts.reserve.description')}></HowToShort>
                            <HowToShort number={4} title={t('project_genesis.howto.shorts.book.title')} description={t('project_genesis.howto.shorts.book.description')}></HowToShort>
                            <HowToShort number={5} title={t('project_genesis.howto.shorts.download.title')} description={t('project_genesis.howto.shorts.download.description')}></HowToShort>
                        </div>
                        <div>
                            <BulletTip number={1} gif={g_tp} title={t('project_genesis.howto.shorts.plan.title')} text={t('project_genesis.howto.shorts.plan.tooltip')}/>
                            <BulletTip number={2} gif={g_Mv} title={t('project_genesis.howto.shorts.preserve.title')} text={t('project_genesis.howto.shorts.preserve.tooltip')}/>
                            <BulletTip number={3} gif={g_tr} title={t('project_genesis.howto.shorts.reserve.title')} text={t('project_genesis.howto.shorts.reserve.tooltip')} />
                            <BulletTip number={4} gif={g_b} title={t('project_genesis.howto.shorts.book.title')} text={t('project_genesis.howto.shorts.book.tooltip')}/>
                            <BulletTip number={5} gif={g_ah} title={t('project_genesis.howto.shorts.download.title')} text={t('project_genesis.howto.shorts.download.tooltip')}/>
                        </div>
                    </div>
                )
            }
        </Section>
    );
}

export default HowTo;